import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Multipliers'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Multipliers`}</h1>
    <p>{`Components designs characteristics like spacing, density, outline width, shadow
strength, and others can be defined and customized using numeric factors which
can be multiplied to get the desired values.`}</p>
    <p>{`A numeric factor value can be set up. Then a multiplier can be provided to get
an specific value.`}</p>
    <p>{`For example, to get dynamic spacing with default factor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme } from '@arwes/design';

const theme = createTheme();

theme.space(1); // 5
theme.space(2); // 10
theme.space(3); // 15
theme.space(0); // 0

// The default \`space\` factor is 5.
`}</code></pre>
    <p>{`With a custom factor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const theme = createTheme({
  space: 8
});

theme.space(1); // 8
theme.space(2); // 16
theme.space(3); // 24
theme.space(0); // 0
`}</code></pre>
    <p>{`The following multipliers are available by default:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`theme.fontScale()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`1`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.space()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`5`}</inlineCode>{`px.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.shadowBlur()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`1`}</inlineCode>{`px.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.shadowSpread()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`1`}</inlineCode>{`px.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.outline()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`1`}</inlineCode>{`px.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.transitionDuration()`}</inlineCode>{` with default factor of `}<inlineCode parentName="li">{`100`}</inlineCode>{`ms.`}</li>
    </ul>
    <h2>{`Custom Multipliers`}</h2>
    <p>{`Other multipliers can be created using the utility: `}<inlineCode parentName="p">{`createThemeFactorMultiplier`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme, createThemeFactorMultiplier } from '@arwes/design';

const theme = createTheme({
  size: createThemeFactorMultiplier(3)
});

theme.size(2); // 6
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      